import React, { FormEvent, RefObject, useRef } from "react";

import classnames from "classnames/bind";

import { ErrorMessage } from "@hookform/error-message";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { FieldErrors, useController, useForm } from "react-hook-form";

import { HiddenFormButton, WideButton } from "@VIEW/components/basic/buttons";
import { BasicCheckbox } from "@VIEW/components/basic/inputs/checkbox";
import { BasicInput } from "@VIEW/components/basic/inputs/input";

import styles from "./EmailForm.module.scss";

const cx: CX = classnames.bind(styles);

export type EmailFormValues = {
    email: string;
    forceUpload: boolean;
};

const schema = Joi.object({
    email: Joi.string()
        .trim()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": "Field is not allowed to be empty",
            "string.email": "Wrong email format",
        }),
    forceUpload: Joi.boolean().strict(true).required(),
});

function EmailForm(props: Props) {
    const { forceUpload: defaultForceUpload, disabled, onSubmit } = props;

    const formButtonRef: RefObject<HTMLButtonElement> = useRef(null);

    const { handleSubmit, control } = useForm<EmailFormValues>({
        defaultValues: {
            email: "",
            forceUpload: defaultForceUpload,
        },
        resolver: joiResolver(schema),
        mode: "onChange",
    });

    const {
        field: email,
        fieldState: { error: emailError },
    } = useController({
        name: "email",
        control,
    });

    const {
        field: forceUpload, //
    } = useController({
        name: "forceUpload",
        control,
    });

    function onValid(values: EmailFormValues) {
        onSubmit(values);
    }

    function onInvalid(_: FieldErrors<EmailFormValues>) {
        //
    }

    return (
        <div className={cx("email-form")}>
            <div className={cx("form-wrapper")}>
                {!disabled ? (
                    <>
                        <div className={cx("description")}>Please enter your email address and upload the file</div>
                        <div className={cx("description")}>
                            XLSx File will be processed at 03:00 am. Tick the checkbox below if you want to process it
                            now
                        </div>
                        <div className={cx("force-upload-wrapper")}>
                            <BasicCheckbox
                                checked={forceUpload.value}
                                disabled={disabled}
                                onChange={(checked: boolean) => {
                                    forceUpload.onChange(checked);
                                }}
                                renderLabel={() => "Process the file immediately"}
                            />
                        </div>
                        <div className={cx("email-wrapper")}>
                            <BasicInput
                                value={email.value}
                                placeholder="Enter email address"
                                type={emailError ? "error" : "default"}
                                disabled={disabled}
                                autoComplete="off"
                                onChange={(value: string) => {
                                    email.onChange(value);
                                }}
                            />
                            <div className={cx("error-message")}>
                                <ErrorMessage
                                    name="email"
                                    errors={{ email: emailError }}
                                />
                            </div>
                        </div>
                        <WideButton
                            title="Start"
                            disabled={disabled}
                            onClick={() => formButtonRef.current?.click()}
                        />
                    </>
                ) : (
                    <div className={cx("description")}>Upload is in progress</div>
                )}
                <form
                    onSubmit={(event: FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        void handleSubmit(onValid, onInvalid)(event);
                    }}
                >
                    <HiddenFormButton ref={formButtonRef} />
                </form>
            </div>
        </div>
    );
}

const onSubmitFn = (values: EmailFormValues): void => void 0;

type Props = {
    forceUpload: boolean;
    disabled: boolean;
    onSubmit: typeof onSubmitFn;
};

export default EmailForm;
